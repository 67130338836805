@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: local('ProximaNovaRegular'), url('./app/assets/fonts/ProximaNovaRegular.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: local('ProximaNovaSemiBold'), url('./app/assets/fonts/ProximaNovaSemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNovaBold'), url('./app/assets/fonts/ProximaNovaBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 800;
  src: local('ProximaNovaXBold'), url('./app/assets/fonts/ProximaNovaXBold.otf') format('opentype');
}

html,
#root,
#root > div:not(.Toastify) {
  height: 100%;
  font-family: 'ProximaNova', 'Roboto';
}

#root > .Toastify {
  font-family: 'ProximaNova', 'Roboto';
}

body {
  margin: 0;
  font-family: 'ProximaNova', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
